<template>
  <v-container fluid fill-height class="pa-0 overflow-hidden">
    <Navbar />
    <alert-status v-if="getAlertStatus !== null" />
    <v-row class="fill-height ma-0 base-wrapper">
      <v-col cols="auto" class="pa-0">
        <Sidebar @itemClick="closeKeypad"/>
      </v-col>
      <v-col :class="[!showCallBlock ? 'py-0 px-6' : 'pa-0', `router-view-${miniVariant ? 'short' : 'long'}`]">
        <router-view></router-view>
      </v-col>

<!--      <v-divider style="margin-right: 1px" vertical></v-divider>-->

      <CallBlock ref="callBlock" :showDialPad="true" v-if="showCallBlock" />
    </v-row>
  </v-container>
</template>

<script>
import Navbar from '@/components/Navbar'
import Sidebar from '@/components/Sidebar'
import AlertStatus from '@/components/Alert'
import resizeMixin from '@/mixins/resize.mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import CallBlock from '../components/sip/CallBlock.vue'
import { getMissedCall, removeMissedCall } from '../utilities/missedCall'
import { getVoicemailStorage } from '../utilities/voicemailHelper'

export default {
  name: 'Base',
  mixins: [resizeMixin],
  components: {
    CallBlock,
    Navbar,
    Sidebar,
    AlertStatus
  },
  data: () => {
    return {
      right_box: 'contact_history'
    }
  },
  async mounted() {
    setTimeout(async () => {
      const number = getMissedCall()
      if (number) {
        removeMissedCall()
        this.$router.replace({ name: 'Calls' })
        this.$refs.callBlock.setPhone(number)
        this.$refs.callBlock.toCall()
      }
      const voiceMailUuid = getVoicemailStorage()
      if (voiceMailUuid && this.$route.name !== 'Voicemails') {
        this.$router.replace({ name: 'Voicemails' })
      }
    }, 500)
  },
  async created() {
    if (!this.userProfile) await this.getUserProfile()
    this.validatePath(this.$route.path)
  },
  watch: {
    '$route.path' (value) {
      this.validatePath(value)
    }
  },
  computed: {
    ...mapState(['sip/phoneNumber', 'showPeople']),
    ...mapGetters(['getAlertStatus', 'GetToggleSidebar', 'userProfile']),
    isAdmin () {
      return this.userProfile && this.userProfile.is_admin
    },
    isAdminPortal () { return this.$route.path.includes('/admin') },
    miniVariant () {
      return !this.$store.getters.GetToggleSidebar
    },
    isAccountUnderTheCompany() {
      return this.userProfile ? !!this.userProfile.company_domain : false
    },
    showCallBlock () {
      return !this.isAdminPortal && (!this.isAccountUnderTheCompany || (this.userProfile && this.userProfile.enable_text))
    }
  },
  methods: {
    ...mapActions(['logout', 'getUserProfile']),
    closeKeypad() {
      if (this.isAdminPortal) return
      this.$refs.callBlock.closeKeypad()
    },
    validatePath (value) {
      if (!value.includes('admin')) {
        if (this.isAdmin) {
          this.$router.push('/admin')
        }
        if (this.isAccountUnderTheCompany) {
          if (!this.userProfile.enable_voice && this.userProfile.enable_text) return this.$router.push({ name: 'Messages' })
          else if (this.userProfile.enable_voice && !this.userProfile.enable_text) return this.$router.push({ name: 'Calls' })
          else if (!this.userProfile.enable_voice && !this.userProfile.enable_text) return this.$router.push({ name: 'Archive' })
        }
        return
      }
      if (this.isAdmin) return
      this.logout()
    }
  }
}
</script>

<style lang="scss">
.base-wrapper {
  flex-wrap: nowrap;
}
@media (min-width: 576px) {
  .router-view-long {
    max-width: calc(100vw - 256px);
  }
  .router-view-short {
    max-width: calc(100vw - 72px);
  }
}
</style>
