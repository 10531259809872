<template>
  <v-navigation-drawer
    class="navigation-drawer"
    clipped
    :fill-height="!fixedClass"
    permanent
    :class="{ fixed: fixedClass }"
    :mini-variant="miniVariant"
    :style="miniVariant ? 'width:72px' : ''"
    :touchless="$vuetify.breakpoint.mdAndDown"
  >
    <v-list dense shaped>
      <v-list-item-group v-model="selectedItem" color="primary">
        <template v-for="(item, i) in computedItems">
          <v-tooltip :key="i" :disabled="!miniVariant" right>
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" :to="item.to" :class="item.class" class="sidebar-item" @click="$emit('itemClick')">
                <v-list-item-icon>
                  <v-badge
                    v-if="item.unreadCountName === 'messages' ? unreadCountMessages > 0 : item.unreadCountName && $store.state.calls.unreadCount[item.unreadCountName] > 0"
                    :content="item.unreadCountName === 'messages' ? unreadCountMessages : $store.state.calls.unreadCount[item.unreadCountName]"
                  >
                    <v-icon :class="{ 'pl-sm-2 pl-md-2': miniVariant }">{{item.icon}}</v-icon>
                  </v-badge>
                  <v-icon v-else :class="{ 'pl-sm-2 pl-md-2': miniVariant }">{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="font-weight:400">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
          <v-divider
            v-if="item.title === 'Voicemails'"
            :key="i+'_divider'"
            class="mt-6 d-none d-sm-block"
          ></v-divider>
        </template>
          <v-tooltip :disabled="!miniVariant" right>
            <template v-slot:activator="{ on }">
              <v-list-item v-if="!isAccountUnderTheCompany && !isAdminPortal && userProfile && userProfile.is_free_user" v-on="on" to="/upgrade" class="nav-upgrade">
                <v-list-item-icon :style="miniVariant ? 'padding-left:10px' : ''">
                  <v-img
                    :src="require('../assets/svg/upgrade-inactive.svg')"
                    width="20"
                    height="20"
                    contain
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="font-weight:400">Upgrade</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          <span>Upgrade</span>
        </v-tooltip>
      </v-list-item-group>
    </v-list>
    <v-divider class="d-none d-sm-block"></v-divider>
    <GoogleAdSense v-if="!isWhitelabel" adSlot="4174655203" styles="display:inline-block;min-width:120px;max-width:360px;width:100%;min-height:200px;max-height:400px;height:100%" sequence="1" />
    <!-- <div class="pa-3" style="height: calc(100vh - 217px);">
      <v-img src="https://via.placeholder.com/150" style="height: 100%;"></v-img>
    </div> -->
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoogleAdSense from './GoogleAdSense.vue'
import { isWhitelabel } from '@/utilities/api'
export default {
  name: 'Sidebar',
  components: { GoogleAdSense },
  emits: ['itemClick'],
  data: () => {
    return {
      isWhitelabel,
      selectedItem: '',
      items: [
        { title: 'Calls', icon: 'mdi-phone', to: '/calls', class: '' },
        { title: 'Messages', icon: 'mdi-message-text-outline', to: '/messages', class: '', counter: true, unreadCountName: 'messages' },
        { title: 'Voicemails', icon: 'mdi-voicemail', to: '/voicemails', class: 'mb-sm-6', counter: true, unreadCountName: 'voicemails' },
        { title: 'Archive', icon: 'mdi-archive', to: '/archive', class: 'mt-sm-6' },
        { title: 'Spam', icon: 'mdi-alert-circle', to: '/spam', class: '' },
        { title: 'Labels', icon: 'mdi-label', to: '/labels', class: '' },
        { title: 'Contacts', icon: 'mdi-contacts', to: '/contacts', class: '' }
      ],
      adminItems: [
        { title: 'User', icon: 'mdi-account', to: '/admin', class: '' },
        { title: 'Payment', icon: 'mdi-credit-card-outline', to: '/admin-payment', class: '' },
        { title: 'Invoice', icon: 'mdi-receipt-text', to: '/admin-invoice', class: '' },
        { title: 'Number', icon: 'mdi-cellphone-text', to: '/admin-number', class: '' },
        { title: 'Call History', icon: 'mdi-phone-log', to: '/admin-call-history', class: '' },
        { title: 'SMS History', icon: 'mdi-message-bulleted', to: '/admin-sms-history', class: '' },
        { title: 'Call Report', icon: 'mdi-phone-alert', to: '/admin-report-call', class: '' },
        { title: 'SMS Report', icon: 'mdi-message-alert', to: '/admin-report-sms', class: '' }
      ],
      resellerItems: [
        { title: 'User', icon: 'mdi-account', to: '/admin', class: '' },
        { title: 'Number', icon: 'mdi-cellphone-text', to: '/admin-number', class: '' },
        { title: 'Call History', icon: 'mdi-phone-log', to: '/admin-call-history', class: '' },
        { title: 'SMS History', icon: 'mdi-message-bulleted', to: '/admin-sms-history', class: '' }
      ]
    }
  },
  mounted() {
    if (!this.isAdminPortal) {
      this.getVoicemailUnreadCount()
    }
  },
  computed: {
    ...mapGetters(['userProfile']),
    unreadCountMessages() {
      return this.$store.state.messages.unreadCount
    },
    fixedClass() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },
    miniVariant () {
      return !this.$store.getters.GetToggleSidebar
    },
    isAdminPortal () {
      return this.$route.path.includes('/admin')
    },
    computedItems () {
      const filter = ['Calls', 'Messages', 'Voicemails']
      if (this.isAccountUnderTheCompany) {
        return this.items.filter(e =>
          !filter.includes(e.title) ||
          (['Calls', 'Voicemails'].includes(e.title) && this.userProfile.enable_voice) ||
          (['Messages'].includes(e.title) && this.userProfile.enable_text))
      }
      return this.isWhitelabel ? this.resellerItems : this.isAdminPortal ? this.adminItems : this.items
    },
    isAccountUnderTheCompany() {
      return this.userProfile ? !!this.userProfile.company_domain : false
    }
  },
  methods: {
    ...mapActions('calls', ['getVoicemailUnreadCount'])
  }
}
</script>

<style scoped>
@media (min-width: 1264px) and (max-width: 1300px) {
  .navigation-drawer {
    width: 217px !important;
  }
}
::v-deep .v-badge__badge {
  background: #27AE60 !important;
}
::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background: #5657af20 !important;
}
</style>
